import React from 'react';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import { useIntl } from 'gatsby-plugin-intl';
import { HomeSlider, Space, View } from 'src/Widgets';
import { Container, Row, Col } from 'react-bootstrap';
import * as Styles from './Styles/index.module.scss';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

interface IIndexPage {}

const IndexPage: React.FC<IIndexPage> = () => {
    const intl = useIntl();

    return (
        <Layout>
            <SEO
                title={intl.formatMessage({ id: 'home.pageTitle' })}
                description={intl.formatMessage({ id: 'home.metaDescription' })}
                lang={intl.locale}
            />

            <HomeSlider />

            <Space height={24} />

            <Container fluid>
                <Row>
                    <Col md={4} sm={12}>
                        <StaticImage
                            src={'../Resources/Images/Home/homeImage.jpg'}
                            className={Styles.homeImage}
                            style={{ objectFit: 'cover' }}
                            alt=""
                        />
                    </Col>
                    <Col
                        md={8}
                        sm={12}
                        className={classnames('pageContent', 'text-center')}>
                        <h1>{intl.formatMessage({ id: 'home.header' })}</h1>

                        <Space height={32} />

                        <p>{intl.formatMessage({ id: 'home.content1' })}</p>
                        <p>{intl.formatMessage({ id: 'home.content2' })}</p>
                        <p>{intl.formatMessage({ id: 'home.content3' })}</p>
                        <p>{intl.formatMessage({ id: 'home.content4' })}</p>

                        <Space height={24} />

                        <Link
                            className={classnames(
                                'btn btn-primary',
                                Styles.actionButton,
                            )}
                            to={`/${intl.locale}/about`}>
                            {intl
                                .formatMessage({
                                    id: 'general.learnMore',
                                })
                                .toUpperCase()}
                        </Link>

                        <Space height={32} />

                        <Row>
                            <Col md={4} sm={12}>
                                <View className={Styles.column}>
                                    <span className={Styles.columnTitle}>
                                        {intl.formatMessage({
                                            id: 'home.column1.title',
                                        })}
                                    </span>
                                    <span className={Styles.columnText}>
                                        {intl.formatMessage({
                                            id: 'home.column1.text',
                                        })}
                                    </span>
                                </View>
                            </Col>
                            <Col md={4} sm={12}>
                                <View className={Styles.column}>
                                    <span className={Styles.columnTitle}>
                                        {intl.formatMessage({
                                            id: 'home.column2.title',
                                        })}
                                    </span>
                                    <span className={Styles.columnText}>
                                        {intl.formatMessage({
                                            id: 'home.column2.text',
                                        })}
                                    </span>
                                </View>
                            </Col>
                            <Col md={4} sm={12}>
                                <View className={Styles.column}>
                                    <span className={Styles.columnTitle}>
                                        {intl.formatMessage({
                                            id: 'home.column3.title',
                                        })}
                                    </span>
                                    <span className={Styles.columnText}>
                                        {intl.formatMessage({
                                            id: 'home.column3.text',
                                        })}
                                    </span>
                                </View>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Space height={48} />
        </Layout>
    );
};

export default IndexPage;
